import React from 'react';

import Layout from '../components/layout';
import Grid from '../components/grid/grid';
import GridItem from '../components/grid/item';
import Hero from '../components/hero/hero';

const AboutUsPage = () => (
  <Layout title="About Us">
    <Hero
      backgroundImage="zoo-pharm"
      headline="About Us"
      tagline=""
    />
    <section className="content-wrapper">
      <Grid justifyContent="center" alignItems="center">
        <GridItem md={8}>
          <h2>ZooPharm</h2>
          <h3>Custom compounds for veterinary medicine</h3>
          <p>
            ZooPharm is an unrivaled veterinary compounding pharmacy dedicated to providing
            pharmaceuticals for the safe and humane care of companion animals, laboratory animals,
             non-domestic species, resident wildlife and captive exotic breeds.
          </p>
          <p>
            Recognizing the unique medication needs of practicing veterinarians and
            research scientists in these distinctive animal health sectors, ZooPharm has become
            the leader in the development, compounding and delivery of highly specialized
            pharmaceutical formulations.
          </p>
          <p><strong>Available custom compounds include such novel medications as:</strong></p>
          <ul>
            <li>Patented immobilization drug combinations and their reversal antagonists</li>
            <li>Sustained release analgesics</li>
            <li>Uncommon, hard-to-find anesthetics and sedatives</li>
            <li>
              And other noteworthy drugs not readily available to veterinarians due
               to discontinuance by their manufacturers or human formulation exclusivity
            </li>
          </ul>
        </GridItem>
      </Grid>
    </section>
  </Layout>
);

export default AboutUsPage;
